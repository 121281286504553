<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Merchants</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between" v-if="hasPermission('ROLE_TDM_VIEW_MERCHANT')">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field label="name" v-model="query.name" single-line outlined @change="search($event, 'name')" data-cy="module-name"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="mobileNumber" v-model="query.mobileNumber" single-line outlined @change="search($event, 'mobileNumber')" data-cy="module-mobile-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="email" v-model="query.email" single-line outlined @change="search($event, 'email')" data-cy="module-email"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="merchantId" v-model="query.merchantId" single-line outlined @change="search($event, 'merchantId')" data-cy="module-merchant-id"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-container fluid>
                                    <v-row align="center">
                                    <v-col cols="3">
                                        <v-subheader>
                                        Enabled: 
                                        </v-subheader>
                                    </v-col>
                                <v-col cols="9">    
                                <v-select
                                    v-model="query.enabled"
                                    :items="items"
                                    label="Select"
                                    data-cy="module-selection-items"
                                    single-line
                                ></v-select>
                                </v-col>
                                </v-row>
                                </v-container>
                            </v-flex>
                            <v-flex xs12 sm6>
                            </v-flex>
                            <v-col cols="12" md="5">
                                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="query.dateFrom" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from">
                                        <v-btn text color="primary" @click="clearFromDate()">Clear
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="query.dateTo" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                                        <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_VIEW_MERCHANT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)" data-cy="button-search">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_TDM_CREATE_MERCHANT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showMerchantForm('CREATED')" data-cy="button-create">Create Merchant</v-btn>
                                <merchant-form ref="merchantForm" :selected-item="selectedMerchant" :merchantCategoryCodes="merchantCategoryCodes" :countries="countries" :is-show="showFormDialog" :form-type="merchantFormType" :paymentAgents="paymentAgents" :isNotPaymentAgent=isNotPaymentAgent v-on:close-dialog="closeDialog" v-on:save-merchant="saveMerchant" />
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayMerchants"
            :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options"
            :server-items-length="totalMerchants" class="mb-14"
            :footer-props="{'items-per-page-options': [10, 20, 50]}">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.name }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.city }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.country.name }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.merchantId }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.merchantCategoryCode.code }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.enabled }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.lastUpdated }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewMerchant(item)" data-cy="module-details">
                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                                <v-btn fab dark x-small color="green" @click.stop="showMerchantForm('UPDATE', item)" v-if="hasPermission('ROLE_TDM_UPDATE_MERCHANT')" data-cy="module-details-edit">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import MerchantForm from '@/views/components/MerchantForm.vue'
import DetailsForm from '@/views/components/DetailsForm.vue'
import moment from 'moment';
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Merchants',
    components: {
        Loading,
        MerchantForm,
        DetailsForm
    },
    data() {
        return {
            headers: [],
            displayMerchants: [],
            remainderMerchants: [],
            paymentAgents: [],
            isNotPaymentAgent: false,
            countries: [],
            merchantCategoryCodes: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            merchantFormType: 'CREATE',
            selectedMerchant: {},
            page: 1,
            numberOfPages: 0,
            totalMerchants: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                id: '',
                name: '',
                mobileNumber: '',
                email: '',
                merchantId: '',
                enabled: '',
                dateFrom: '',
                dateTo: ''
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            items: [
                '',
                'true',
                'false'
                ],
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
            }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'merchant',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.query.dateFrom)
            return this.query.dateFrom;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.query.dateTo)
            return this.query.dateTo;
        }
    },
    async mounted() {
        this.setHeaders()
        this.setDefaultDateRange()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const transactionListResponse = await API.searchMerchants(pageNumber, limit, this.query)
                console.log(JSON.stringify(transactionListResponse))
                if (!transactionListResponse || transactionListResponse.error) {
                    //error getting data
                    console.log(`${transactionListResponse.error}`)
                } else {
                    var filteredList = transactionListResponse.merchants
                    this.isNotPaymentAgent = transactionListResponse.isNotPaymentAgent
                    if (page > 1 && this.remainderMerchants.length > 0) {
                        filteredList = this.remainderMerchants.concat(filteredList)
                    }
                    this.totalMerchants = transactionListResponse.totalItems
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayMerchants = filteredList.slice(0, end)
                    this.remainderMerchants = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = transactionListResponse.pageCount
                    // const hasMoreResponse = await API.searchMerchants(this.totalMerchants, 1, this.query)
                    // if (!hasMoreResponse || hasMoreResponse.error) {
                    //     //error getting data
                    //     console.log(`${hasMoreResponse.error}`)
                    // } else {
                    //     var hasMoreList = hasMoreResponse.merchants
                    //     if(hasMoreList.length > 0) {
                    //       this.totalMerchants = this.totalMerchants + 1
                    //       this.numberOfPages = this.numberOfPages + 1
                    //     }
                    // }
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async setData() {
            try {
                const listResponse = await API.getAllCountries()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.countries = listResponse.countries
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getAllMerchantCategoryCodes()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.merchantCategoryCodes = listResponse.merchantCategoryCodes
                }
            } catch (e) {
                console.log(e)
            }
            try {
                const listResponse = await API.getAllPaymentAgents()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.paymentAgents = listResponse.paymentAgent
                }
            } catch (e) {
                console.log(e)
            }
        },
        setHeaders() {
            var headers = [{
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'City',
                    value: 'city'
                },
                {
                    text: 'Country',
                    value: 'country.name'
                },
                {
                    text: 'Merchant ID',
                    value: 'merchantId'
                },
                {
                    text: 'Merchant Category Code',
                    value: 'merchantCategoryCode.code'
                },
                {
                    text: 'Enabled',
                    value: 'enabled'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Last Updated',
                    value: 'lastUpdated'
                }
            ]
            this.headers = headers
        },
        showMerchantForm(what, item = {}) {
            this.merchantFormType = what
            this.selectedMerchant = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveMerchant(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new merchant <strong><i>${value.name}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createMerchant(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            let message = createResponse.error.response.data.message
                            let errorCode = createResponse.error.response.data.statusCode
                            this.loadingMessage = "Error " + errorCode + ": " + message
                        } else {
                            this.loadingMessage = `Successfully created merchant <strong><i>${value.name}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.merchantForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating merchant <strong><i>${value.name}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateMerchant(value)
                        console.log(updateResponse)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            let message = updateResponse.error.response.data.message
                            let errorCode = updateResponse.error.response.data.statusCode
                            this.loadingMessage = "Error " + errorCode + ": " + message
                        } else {
                            this.loadingMessage = `Successfully updated merchant <strong><i>${value.name}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.merchantForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewMerchant(merchant) {
            console.log(merchant)
            this.details.title = merchant.name
            this.details.item = merchant
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.query.dateFrom = ''
            this.query.dateTo = ''
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.query.dateFrom)
        },
        clearToDate() {
            this.query.dateTo = ''
            this.toDateMenu = false
        },
        clearFromDate() {
            this.query.dateFrom = ''
            this.fromDateMenu = false
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
